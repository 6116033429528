import React from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  MdExitToApp as LogoutIcon,
  MdKeyboardArrowDown as OpenMenuIcon,
  MdSettings as SettingsIcon,
  MdShoppingCart as MyCartIcon,
  MdShopTwo as MyOrdersIcon,
  MdStore as SalesPointIcon
} from 'react-icons/md'
import logout from '@helpers/auth/logout'
import withUserId from '@helpers/auth/withUserId'
import getContrastColor from '@helpers/misc/getContrastColor'
import {EVENTS, sendPosthogData} from '@helpers/posthog'
import getPathname from '@helpers/router/getPathname'
import withWebsiteId from '@helpers/websites/withWebsiteId'
import sleep from '@packages/justo-parts/lib/helpers/sleep'
import withGraphQL from '@packages/react-apollo-decorators/lib/withGraphQL'
import autobind from 'autobind-decorator'
import gql from 'graphql-tag'
import Link from 'next/link'
import {NextRouter, withRouter} from 'next/router'
import {TFunction, withTranslation} from 'next-i18next'

import Points from './Points/index'
import AdminLink from './AdminLink'
import canViewAdmin from './canViewAdmin'
import canViewPos from './canViewPos'
import LoginButton from './LoginButton'

import styles from './styles.module.css'

type ComponentProps = {
  open: boolean
  closeMenu: any
  toggleOpen: any
  location: any
  me: any
  pointsConfiguration: any
  userId: string
  buttonColor: string
  onlyOptions: boolean
  router: NextRouter
  t: TFunction
  websiteId: string
}

type ComponentState = {
  open: boolean // like this
}

const translatePrefix = 'layout.layouts.default.navbar.menu'

@withWebsiteId
@withGraphQL(
  gql`
    query getMeInNavbar($websiteId: ID) {
      me {
        _id
        name
        email
        roles
        roleInWebsite(websiteId: $websiteId) {
          _id
          role
        }
        pointsInWebsite(websiteId: $websiteId)
      }
    }
  `,
  {
    loading: null
  }
)
// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withUserId
class User extends React.Component<ComponentProps, ComponentState> {
  constructor(props) {
    super(props)
  }

  state = {open: this.props.open}

  componentDidMount() {
    window.addEventListener('mouseup', this.closeMenu, false)
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.closeMenu)
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.open !== this.props.open) {
      this.setState({open: this.props.open})
    }
  }

  @autobind
  async closeMenu(event) {
    if (!this.state.open) return true
    await sleep(100)
    this.setState({open: false})
    if (this.props.closeMenu) {
      this.props.closeMenu()
    }
  }

  @autobind
  toggleMenu() {
    this.setState({open: !this.state.open})
    if (this.props.toggleOpen) {
      this.props.toggleOpen()
    }
  }

  @autobind
  async logout() {
    await logout()
  }

  @autobind
  login() {
    sendPosthogData(EVENTS.clicked.login)
    this.props.router.push('/login?to=' + encodeURIComponent(getPathname(this.props.router)))
  }

  renderAdmin() {
    const canView = canViewAdmin(this.props.me)
    if (!canView) return null
    return <AdminLink />
  }

  renderSalesPoint() {
    const canView = canViewPos(this.props.me)
    if (!canView) return null
    return (
      <a href="https://pos.getjusto.com" target="blank" className={styles.menuLink}>
        <SalesPointIcon size={20} />
        <span>{this.props.t(`${translatePrefix}.labelPointOfSale`)}</span>
      </a>
    )
  }

  renderMenu() {
    if (!this.props.me) return null
    if (!this.state.open) return null
    return (
      <div className={styles.menu} key="menu">
        <Link href="/settings" passHref>
          <a className={styles.account}>
            <div className={styles.name}>
              {this.props.me.name === 'Sin nombre' ? 'Mi cuenta' : this.props.me.name}
            </div>
            <div className={styles.email}>{this.props.me.email}</div>
          </a>
        </Link>
        <Points user={this.props.me} config={this.props.pointsConfiguration} />
        <Link href={'/checkout'}>
          <a className={styles.menuLink}>
            <MyCartIcon size={20} />
            <span>{this.props.t('fields.myCart', {ns: 'generic'})}</span>
          </a>
        </Link>
        <Link href="/pedidos">
          <a className={styles.menuLink}>
            <MyOrdersIcon size={20} />
            <span>{this.props.t('fields.myOrders', {ns: 'generic'})}</span>
          </a>
        </Link>
        <Link href="/settings">
          <a className={styles.menuLink}>
            <SettingsIcon size={20} />
            <span>{this.props.t(`${translatePrefix}.labelMyAccount`)}</span>
          </a>
        </Link>
        {this.renderAdmin()}
        {this.renderSalesPoint()}
        <div className={styles.logoutIcons}>
          <a onClick={this.logout} className={styles.menuLink}>
            <LogoutIcon size={20} />
            <div>{this.props.t(`${translatePrefix}.labelLogOut`)}</div>
          </a>
        </div>
      </div>
    )
  }

  renderIcon() {
    if (this.props.me) {
      return (
        <div className={styles.menuText} onClick={this.toggleMenu}>
          {this.props.me.name === 'Sin nombre'
            ? this.props.t(`${translatePrefix}.labelMyAccount`)
            : this.props.me.name}{' '}
          <OpenMenuIcon />
        </div>
      )
    } else if (!this.props.userId) {
      return <LoginButton />
    } else {
      return (
        <div className={styles.menuText}>{this.props.t(`${translatePrefix}.labelLoading`)}</div>
      )
    }
  }

  render() {
    if (this.props.onlyOptions)
      return (
        <ReactCSSTransitionGroup
          transitionName="user-menu"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}>
          {this.renderMenu()}
        </ReactCSSTransitionGroup>
      )

    return (
      <div
        className={styles.container}
        style={{
          background: `${this.props.buttonColor}`,
          color: getContrastColor(this.props.buttonColor || '#111')
        }}>
        {this.renderIcon()}
        <ReactCSSTransitionGroup
          transitionName="user-menu"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}>
          {this.renderMenu()}
        </ReactCSSTransitionGroup>
      </div>
    )
  }
}

export default withTranslation()(withRouter<ComponentProps>(User))
