import React from 'react'
import {MdControlPoint} from 'react-icons/md'
import getAdminDashboardUrl from '@helpers/websites/getAdminDashboardUrl'
import useWebsiteId from '@hooks/useWebsiteId'
import Link from 'next/link'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function AdminLink() {
  const {t} = useTranslation('website', {keyPrefix: 'layout.layouts.default.navbar.menu'})
  const websiteId = useWebsiteId()

  return (
    <Link href={getAdminDashboardUrl(websiteId, '')} passHref>
      <a target="_blank" className={styles.menuLink}>
        <MdControlPoint size={20} />
        <span>{t(`labelAdmin`)}</span>
      </a>
    </Link>
  )
}
