import React from 'react'
import formatNumber from '@i18n/formatNumber'
import Link from 'next/link'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function Points(props) {
  const {t} = useTranslation('website', {keyPrefix: 'layout.layouts.default.navbar.menu.points'})
  if (!props.config) return null
  if (!props.config.active) return null
  const pointsInWebsite = props.user.pointsInWebsite
  const name = props.config.name
  const descriptionText =
    pointsInWebsite >= 1000 ? 'labelRedeemForDiscountsCoupons' : 'labelPurchaseAccumulates'
  return (
    <Link href="/canjear">
      <div className={styles.button}>
        <div className={styles.title}>
          {t('labelAmountPoints', {points: formatNumber(pointsInWebsite, '0,0.[00]'), name})}
        </div>
        <div className={styles.description}>{t(descriptionText, {name})}</div>
      </div>
    </Link>
  )
}
